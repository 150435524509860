@media screen and (min-width: 993px) {
  .green-line {
    width: 100%;
    height: 50px;
    position: relative;
    z-index: 1;
    background-color: var(--color-primary);
  }
  .navbar {
    padding: 30px;
    position: relative;
    z-index: 1;
    background-color: var(--text-color);
    .teva-cann-logo {
      width: 154px;
      height: 28px;
    }
    .teva-logo {
      width: 84px;
      height: 29px;
    }
  }
  .header {
    width: 100%;
    height: 70vh;
    align-items: stretch;
    flex-direction: row-reverse;
    position: relative;
    .header-image {
      width: 65%;
      position: relative;
      z-index: 0;
    }
    .header-label {
      background-color: var(--color-primary);
      float: right;
      position: absolute;
      right: 0px;
      top: -18%;
      z-index: 0;
      border-radius: 50% 0 0 50%;
      height: 141%;
      width: 42%;
      display: flex;
      align-items: center;
      h1 {
        color: var(--text-color);
        font-weight: 400;
        width: 20vw;
        height: 50%;
        margin-right: 40%;
      }
      h1::before {
        margin-bottom: 1rem;
        max-width: 3rem;
        display: block;
        content: "";
        height: 2px;
        width: 100%;
        background-color: var(--text-color);
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .navbar {
    display: flex;
    align-items: center;
    padding: 0 1.25rem;
    height: 3.125rem;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    .teva-cann-logo {
      width: 6.25rem;
      display: block;
    }
    .teva-logo {
      display: none;
    }
  }
  .header {
    display: block;
    margin-bottom: 30px;
    position: relative;
    .header-image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      display: block;
      position: relative;
    }
    .header-label {
      position: relative;
      color: var(--text-color);
      background-color: var(--color-primary-2);
      h1 {
        position: relative;
        z-index: 1;
        font-weight: 400;
        margin-top: 0px;
        padding: 15px;
        margin-right: 8%;
      }
      h1::before {
        margin-bottom: 0.9375rem;
        max-width: 3.125rem;
        display: block;
        content: "";
        height: 2px;
        width: 100%;
        background-color: var(--text-color);
      }
    }
    .header-label::after {
      content: "";
      background-color: var(--color-primary);
      position: absolute;
      width: 97%;
      height: 100%;
      top: 0;
      border-radius: 2% 0 0 2%/50%;
    }
  }
}
