
form {
  text-align: right;
}

@media screen and (min-width: 993px) {
  .form-container{
    position: relative;
    z-index: 1;
    background-color: white;
    padding-top: 50px;
    .form-div {
      direction: rtl;
      width: 65%;
      margin-top: 5%;
      margin-right: 11%;
      margin-bottom: 5%;
      .conditions {
        margin-bottom: 50px;
      }
      .submit-button {
        background-color: var( --color-primary);
        color: var(--text-color);
        height: 40px;
        border-radius: 18px;
        width: 130px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .form-div {
    direction: rtl;
    margin: auto;
    width: 80%;
    margin-bottom: 50px;
    .form-header {
      margin-bottom: 60px;
      text-align: right;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      color: var( --color-primary);
    }
    .submit-button {
      background-color: var( --color-primary);
      color: var(--text-color);
      height: 40px;
      border-radius: 18px;
      width: 130px;
    }
    .conditions {
      margin-bottom: 50px;
    }
  }
  .slices-line {
    list-style: none;
    border: solid #00567a;
    border-width: 1px 0;
    margin: 0;
    padding: 1.25rem 0;
  }
}
