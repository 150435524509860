.form-element {
  margin-top: 10px;
  margin-bottom: 10px;
  .form-input {
    display: block;
    width: 85%;
    height: 6vh;
    background-color: var( --input-color);
    border: none;
    border-bottom: 1px solid var(--color-primary);
    @media screen and (max-width: 992px) {
      width: 100%;
  }
}
}
