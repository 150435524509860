.form-element {
  margin-top: 10px;
  margin-bottom: 10px;
  .form-input {
    display: block;
    width: 100%;
    height: 5vh;
    background-color: var( --input-color);
    border: none;
    border-bottom: 1px solid var(--color-primary);
    resize: vertical;
  }
}
