.file-input {
  margin-bottom: 20px;
  .custom-file {
    position: relative;
    width: 85%;
    height: 6vh;
    @media screen and (max-width: 992px) {
      width: 100%;
  }
  .custom-file-label{
    @media screen and (max-width: 992px) {
     font-size: 12px;
  }
  }
    .custom-file-input {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      margin: 0;
      overflow: hidden;
      opacity: 0;
    }
    .custom-file-label {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 0;
      padding: 0.75rem 1.25rem;
      overflow: hidden;
      background-color: #ecf1ee;
      border: 1px solid var(--color-primary);
    }

    .custom-file-input ~ .custom-file-label::after {
      content: "העלאת קובץ";
    }
    .custom-file-label::before {
      position: relative;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      content: "";
    }
    .custom-file-label::after {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 3;
      display: block;
      height: calc(1.625em + 1.5rem);
      padding: 0.75rem 1.25rem;
      line-height: 1.625;
      color: var(--main-color);
      content: "Browse";
      background-color: #eaeaea;
      border-right: inherit;
    }
  }
  .file-name {
    display: block;
    color: var(--file-name);
    font-size: 16px;
    margin-top: 5px;
    @media screen and (max-width: 992px) {
      font-size: 12px;
      margin-top: 0px;
   }
  }
}
