a {
  color: var(--color-secondary-1);
  text-decoration: underline;
  background-color: transparent;
  transition: color .3s;
}
label{
  display: inline-block;
}
p{
  @media screen and (min-width: 993px) {
  width: 90%;
  }
}